import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, LogOut, reset } from "../../features/authSlice";
import { Button, Tabs, Modal } from "flowbite-react";
import LayoutQuestion from "./layoutQuestion";
import axios from "axios";

const customTheme = {
  tablist: {
    base: "hidden",
  },
};

const questionsArray2 = [
  {
    soal_type: 1,
    questionsArray: [
      {
        code_question: "1",
        created_by: "Afanin Enno Zhahira",
        questionText: "Pilih jawaban yang paling benar. Tugas Seorang Satpam PKSS??"
        ,
        choices: [
          {
            choiceText: "Menyelenggarakan keamanan, ketertiban dan melindung.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Menyelenggarakan keamanan, ketertiban, melindung dan mengayomi.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText: "Menyelenggarakan pengamanan, ketertiban dan melindung.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Menyelenggarakan pengamanan, ketertiban, patroli dan keamanan.",
            value: 0,
            correctChoiceIndex: true,
          },
        ],
      },
      {
        code_question: "2",
        created_by: "Afanin Enno Zhahira",
        questionText: "Pilih jawaban yang paling benar. Grooming Seorang Satpam?"
        ,
        choices: [
          {
            choiceText: "Menggunakan topi saat diluar ruangan, rambut cepak 3 2 1, diwarnai, Wanita panjang rambut tidak menyentuh bahu, Mengenakan KTPP dengan Card Holder terbaca, tidak terbalik dan dijepit di saku kiri, Satpam Pria hanya mengenakan 4 titik aksesoris. Kaos dalam berwarna putih bertuliskan “SATPAM” di belakang (berwarna Krem), Mengenakan kaos kaki berwarna hitam polos dan menutupi mata kaki.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Menggunakan topi saat diluar ruangan, rambut cepak 3 2 1, diwarnai, Wanita panjang rambut tidak menyentuh bahu, Mengenakan KTPP dengan Card Holder, tidak terbalik dan dijepit di saku kiri. Kaos dalam berwarna putih bertuliskan “SATPAM” di belakang (berwarna Krem), Mengenakan kaos kaki berwarna putih polos dan menutupi mata kaki.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Menggunakan topi saat diluar ruangan, rambut cepak 3 2 1, tidak diwarnai, Wanita panjang rambut tidak menyentuh bahu. Mengenakan KTPP dengan Card Holder, terbaca, tidak terbalik dan dijepit di saku kiri. Kaos dalam berwarna putih bertuliskan “SATPAM” di belakang (berwarna Krem). Mengenakan kaos kaki berwarna hitam polos dan menutupi mata kaki.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Menggunakan topi saat diluar ruangan, rambut cepak 3 2 1, diwarnai, Wanita panjang rambut tidak menyentuh bahu, Mengenakan KTPP dengan Card Holder, terbalik dan dijepit di saku kiri. Kaos dalam berwarna putih bertuliskan “SATPAM” di belakang (berwarna Krem), Mengenakan kaos kaki berwarna hitam polos dan menutupi mata kaki.",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "3",
        created_by: "Afanin Enno Zhahira",
        questionText: "Pilih jawaban yang paling benar. Grooming Seorang Satpam?"
        ,
        choices: [
          {
            choiceText: "Pada pakaian PDH Pin kompetensi Satpam berbahan logam sesuai kualifikasi berada di saku dada sebelah kiri.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Pada pakaian PDH Pin kompetensi Satpam berbahan logam sesuai kualifikasi berada di atas tulisan “SATPAM’ sebelah kiri.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Pada pakaian PDH Pin kompetensi Satpam berbahan logam sesuai kualifikasi berada di saku dada sebelah kanan.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Pada pakaian PDH Pin kompetensi Satpam berbahan logam sesuai kualifikasi berada di atas tulisan “NAMA” sebelah kanan",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "4",
        created_by: "Afanin Enno Zhahira",
        questionText: "Peralatan yang wajib ada pada Satpam?"
        ,
        choices: [
          {
            choiceText: "Tongkat Besi, HP, Borgol.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Borgol, Cincin Akik, Sangkur",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Tongkat T, Borgol",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Borgol, Kunci L",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "5",
        created_by: "Afanin Enno Zhahira",
        questionText: "Kepanjangan dari PDL? "
        ,
        choices: [
          {
            choiceText: "Pakaian Dinas Luar",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Pakaian Dinas Lapangan",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText: "Pakaian Dinas Lapang",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Pakaian Dinas Harian",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "6",
        created_by: "Afanin Enno Zhahira",
        questionText: "Memberikan rasa aman dan perlindungan, serta memberikan pengarahan terkait alur pelayanan produk perusahaan atau saat terjadi keadaan darurat merupakan arti dari?"
        ,
        choices: [
          {
            choiceText: "Pengawalan (Navigator)",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Penegak peraturan tata tertib perusahaan (Role Model)",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Patroli",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Kemampuan Intelijen",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "7",
        created_by: "Afanin Enno Zhahira",
        questionText: "Penjagaan aset bergerak dan tidak bergerak, baik milik perusahaan maupun tamu perusahaan merupakan arti dari?"
        ,
        choices: [
          {
            choiceText: "Pengawalan (Navigator)",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Penegak peraturan tata tertib perusahaan (Role Model)",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Penjagaan Aset",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Kemampuan Intelijen",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "8",
        created_by: "Afanin Enno Zhahira",
        questionText: "Apel dilakukan saat?"
        ,
        choices: [
          {
            choiceText: "Shift Pagi, Shift Malam",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Shift Siang, Shift Sore",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Shift Pagi",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Pergantian Shift",
            value: 0,
            correctChoiceIndex: true,
          },
        ],
      },
      {
        code_question: "9",
        created_by: "Afanin Enno Zhahira",
        questionText: "Yang wajib ditandatangani oleh Petugas Satpam saat serah terima tugas adalah?"
        ,
        choices: [
          {
            choiceText: "Buku Tamu",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Buku Register/Mutasi",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText: "Buku Keuangan",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Buku Harian",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "10",
        created_by: "Afanin Enno Zhahira",
        questionText: "Menerapkan etika kerja, nilai-nilai kebijakan dan peraturan perusahaan, jujur dan bertanggung jawab merupakan?"
        ,
        choices: [
          {
            choiceText: "Profesional",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Reliabel",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Integritas",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Gesture",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "11",
        created_by: "Afanin Enno Zhahira",
        questionText: "Pengertian dari 5S?"
        ,
        choices: [
          {
            choiceText: "Senyum, Salam, Sapa, Sopan, Santun.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Senyum, Salam, Suka, Sapa, Santun.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Senyum, Sopan, Santun, Salam.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Sapa, Salam, Sopan",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "12",
        created_by: "Afanin Enno Zhahira",
        questionText: "Yang dilakukan Satpam saat melakukan pengawasan parkiran kendaraan adalah?"
        ,
        choices: [
          {
            choiceText: "Beristirahat di tempat yang tidak terjangkau CCTV",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Merokok di area parker",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Bermain HP",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Melakukan pengaturan lalu lintas",
            value: 0,
            correctChoiceIndex: true,
          },
        ],
      },
      {
        code_question: "13",
        created_by: "Afanin Enno Zhahira",
        questionText: "Satpam tidak boleh, kecuali?"
        ,
        choices: [
          {
            choiceText: "Berjudi, mabuk-mabukan, menggunakan obat terlarang",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Menghina, mengancam atau menganiaya pekerja",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Menjadi navigator dan memahami produk dan layanan",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Bermain Hp saat bertugas dan merokok",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "14",
        created_by: "Afanin Enno Zhahira",
        questionText: "Dalam 'SERVICE VALUE SATPAM PT PKSS,' komitmen untuk 'Siap dalam bertugas' mencerminkan"
        ,
        choices: [
          {
            choiceText: "Keterampilan berbicara yang baik.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Kepatuhan terhadap peraturan perusahaan.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Kesiapan fisik dan mental dalam melaksanakan tugas.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "Pemahaman produk layanan perusahaan.",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "15",
        created_by: "Afanin Enno Zhahira",
        questionText: "Bagian mana dari kendaraan yang diperiksa dengan menggunakan cermin detector?"
        ,
        choices: [
          {
            choiceText: "Bagasi kendaraan",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Bagian dalam kendaraan",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Bagian atas kendaraan",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Bagian bawah kendaraan",
            value: 0,
            correctChoiceIndex: true,
          },
        ],
      },
      {
        code_question: "16",
        created_by: "Afanin Enno Zhahira",
        questionText: "Apa yang menjadi tujuan utama dari melaksanakan patroli dengan mengubah rute patroli dan waktu kontrol?"
        ,
        choices: [
          {
            choiceText: "Mengidentifikasi sumber-sumber bahaya potensial.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Mencegah orang lain mengidentifikasi petugas patroli.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText: "Memahami situasi dan keadaan unit kerja area parkir.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Mengetahui lokasi APAR / Hydrant yang tepat.",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "17",
        created_by: "Afanin Enno Zhahira",
        questionText: "Mana di antara pernyataan berikut yang TIDAK mencerminkan seorang satpam, kecuali?"
        ,
        choices: [
          {
            choiceText: "terlihat oleh nasabah saat berada di dalam Banking Hall.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText:
              "menerima tip dalam situasi dan kondisi apapun.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "tidur saat jam kerja.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "bermain HP pada saat jadwal tugas.",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "18",
        created_by: "Afanin Enno Zhahira",
        questionText: "Salah satu larangan yang diberlakukan bagi seorang satpam adalah 'Tidak Produktif.' Apa yang dimaksud dengan larangan ini?"
        ,
        choices: [
          {
            choiceText: "Tidak boleh melakukan aktivitas apa pun selama jam kerja.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Tidak boleh melakukan aktivitas yang tidak ada hubungannya dengan tugas satpam.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText: "Tidak boleh mengejar produktivitas dalam pekerjaan sehari-hari.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Tidak boleh melakukan tugas keamanan di luar Banking Hall.",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "19",
        created_by: "Afanin Enno Zhahira",
        questionText: "Salah satu larangan bagi seorang satpam adalah 'Membawa Orang Lain.' Mengapa aturan ini diberlakukan dengan ketat?"
        ,
        choices: [
          {
            choiceText: "Karena satpam tidak boleh memiliki teman selama bekerja.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Karena satpam harus selalu berfokus pada tugasnya tanpa terganggu oleh orang lain.",
            value: 0,
            correctChoiceIndex: true,
          },
          {
            choiceText: "Karena satpam tidak boleh memiliki kehidupan sosial di luar jam kerja.",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Karena satpam tidak menjaga perusahaan.",
            value: 0,
            correctChoiceIndex: false,
          },
        ],
      },
      {
        code_question: "20",
        created_by: "Afanin Enno Zhahira",
        questionText: "Apa yang menjadi perbedaan antara pakaian dinas harian (PDH) dan pakaian dinas lapangan (PDL)?"
        ,
        choices: [
          {
            choiceText: "Warna kemeja dan lengan",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Panjang celana",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText: "Warna celana",
            value: 0,
            correctChoiceIndex: false,
          },
          {
            choiceText:
              "Panjang kemeja dan panjang lengan",
            value: 0,
            correctChoiceIndex: true,
          },
        ],
      },
    ],
  }
];


const randomQuestion = questionsArray2[Math.floor(Math.random() * questionsArray2.length)];
const soalTypeQuestions = questionsArray2.filter(
  (question) => question.soal_type === randomQuestion.soal_type
);

function Question() {
  //! AUTH & RULES FUNCTION
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  const soal_type = randomQuestion.soal_type;

  const [sessionEndTime, setSessionEndTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  //!-----------------------------
  const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (dataUuid) {
        try {
          const response = await fetch('https://apiprimacademy.pkss.co.id/me', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': dataUuid,
            },
          });
          setDatas(await response.json());
        } catch (error) {
          console.error('Kesalahan saat mengambil data profil:', error);
        }
      } else {
        alert('Anda belum melakukan login');
        return navigate("/");
        // Opsional: Tangani pengalihan di sini menggunakan useNavigate
      }
    };

    fetchData();
  }, [dataUuid]);

  useEffect(() => {
    const sessionDurationMinutes = 60;
    const now = new Date();
    const endTime = new Date(now.getTime() + sessionDurationMinutes * 60000);
    setSessionEndTime(endTime);
    return () => {
      clearInterval(timeRemaining);
    };
  }, []);

  // Efek kedua: menghitung waktu yang tersisa dan melakukan logout otomatis jika waktu habis
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    
    const calculateTimeRemaining = () => {
      const now = new Date();
      const timeDifference = sessionEndTime - now;
      const secondsRemaining = Math.max(0, Math.floor(timeDifference / 1000));
      setTimeRemaining(secondsRemaining);
    };
    calculateTimeRemaining();
    const timerInterval = setInterval(() => {
      calculateTimeRemaining();
      if (timeRemaining <= 0) {
        setAlertShown(true);
        alert("Waktu sudah habis");

        //! LAKUKAN PENGIMPUTAN DATA DISINI TIME OUT
        saveResultTimeOut();
        // Lakukan tindakan logout otomatis
        {
          dispatch(LogOut());
          dispatch(reset());
          navigate("/");
        }
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [dispatch, navigate, sessionEndTime, timeRemaining]);

  //! QUESTION FUNCTION
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const props = { setActiveTab, tabsRef };

  function shuffleArray(array) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  const [shuffledQuestions, setShuffledQuestions] = useState(
    soalTypeQuestions[0].questionsArray.map((question) => ({
      ...question,
      userAnswerCorrect: null, // disini
    }))
  );

  useEffect(() => {
    const shuffledQuestions = shuffleArray(soalTypeQuestions[0].questionsArray);
    setShuffledQuestions(shuffledQuestions);
  }, []);

  const [selectedChoices, setSelectedChoices] = useState(
    shuffledQuestions.map(() => null)
  );

  useEffect(() => {
    let correctCount = 0;
    let incorrectCount = 0;

    selectedChoices.forEach((selectedChoice, index) => {
      if (selectedChoice !== null) {
        const isChoiceCorrect =
          shuffledQuestions[index].choices[selectedChoice].correctChoiceIndex;

        if (isChoiceCorrect) {
          correctCount++;
        } else {
          incorrectCount++;
        }
      }
    });

    setNumCorrectChoices(correctCount);
    setNumIncorrectChoices(incorrectCount);
  }, [selectedChoices]);

  const [numCorrectChoices, setNumCorrectChoices] = useState(0);
  const [numIncorrectChoices, setNumIncorrectChoices] = useState(0);

  const [hasAnswered, setHasAnswered] = useState(
    shuffledQuestions.map(() => false)
  );

  // Fungsi untuk menangani perubahan pilihan radio
  const handleRadioChange = (questionIndex, choiceIndex) => {
    const newSelectedChoices = [...selectedChoices];
    newSelectedChoices[questionIndex] = choiceIndex;
    setSelectedChoices(newSelectedChoices);

    // Set hasAnswered untuk pertanyaan ini menjadi true
    // const newHasAnswered = [...hasAnswered];
    // newHasAnswered[questionIndex] = true;
    // setHasAnswered(newHasAnswered);

    const newHasAnswered = [...hasAnswered];
    newHasAnswered[questionIndex] = true;
    setHasAnswered(newHasAnswered);

    const isChoiceCorrect =
      shuffledQuestions[questionIndex].choices[choiceIndex].correctChoiceIndex;

    const updatedQuestions = [...shuffledQuestions];
    updatedQuestions[questionIndex].userAnswerCorrect = isChoiceCorrect;
    setShuffledQuestions(updatedQuestions);
  };

  //! UPLOAD FUNTION
  // const { user } = useSelector((state) => state.auth);
  const nameUser = datas?.name;
  const nikUser = datas?.nik;
  const uuidUser = datas?.uuid;
  const [msg, setMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  const saveResult = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // const baseURL = "http://localhost:81";
    // const baseURL = "http://182.253.211.156:81";
    const baseURL = "https://apiprimacademy.pkss.co.id";
    try {
      await axios.post(`${baseURL}/results`, {
        uuid: uuidUser,
        name: nameUser,
        nik: nikUser,
        soal_type: soal_type,
        benar: numCorrectChoices,
        salah: numIncorrectChoices,
        test_id: 7,
      });
      // Save the Answers
      {
        shuffledQuestions.map(async (question, index) => (
          await axios.post(`${baseURL}/answers`, {
            uuid: uuidUser,
            nik: nikUser,
            test_id: 6,
            soal_type: soal_type,
            code_question: question.code_question,
            answer: question.userAnswerCorrect ? "true" : "false",
          })
        ))
      }
      setIsLoading(false);
      window.location.href = "https://primacademy.pkss.co.id/thank";
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const saveResultTimeOut = async () => {
    // const baseURL = "http://182.253.211.156:81";
    const baseURL = "https://apiprimacademy.pkss.co.id";
    try {
      // Calculate the number of answered and unanswered questions
      const answeredQuestions = selectedChoices.filter((choice) => choice !== null);
      const unansweredCount = shuffledQuestions.length - answeredQuestions.length;

      // If there are unanswered questions, consider all answers as incorrect
      // const correctCount = unansweredCount > 0 ? numCorrectChoices : numCorrectChoices;
      const correctCount = unansweredCount > 0 ? numCorrectChoices : numCorrectChoices;

      // Save the results
      await axios.post(`${baseURL}/results`, {
        uuid: uuidUser,
        name: nameUser,
        nik: nikUser,
        soal_type: soal_type,
        benar: correctCount,
        salah: shuffledQuestions.length - correctCount,
        test_id: 6,
      });
      // Save the Answers
      {
        shuffledQuestions.map(async (question, index) => (
          await axios.post(`${baseURL}/answers`, {
            uuid: uuidUser,
            nik: nikUser,
            test_id: 6,
            soal_type: soal_type,
            code_question: question.code_question,
            answer: question.userAnswerCorrect ? "true" : "false",
          })
        ))
      }
      setIsLoading(false);
      window.location.href = "https://primacademy.pkss.co.id/thank";
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setMsg(error.response.data.msg);
        setShowModal(true);
      }
    }
  };

  return (
    <LayoutQuestion>
      {datas ? (
        <div className="relative bg-scroll">
          <div className="px-2">
            <Tabs.Group
              aria-label="Default tabs"
              style="pills"
              ref={props.tabsRef}
              onActiveTabChange={(tab) => props.setActiveTab(tab)}
              theme={customTheme}
            >
              {shuffledQuestions.map((question, index) => (
                <Tabs.Item key={index} active title={`Soal ${index + 1}`}>
                  <div className="w-full h-auto bg-white rounded-xl shadow-2xl">
                    <div className="w-full overflow-y-auto max-h-screen">
                      {" "}
                      {/* Tambahkan class overflow-y-auto dan max-h-screen */}
                      <div className="p-4 items-center justify-between">
                        <div className="flex-1">
                          <p className="font-bold text-xl lg:text-2xl text-center border-b-4 border-blue-800 pb-4">
                            Question {index + 1}/ {shuffledQuestions.length}
                          </p>
                          <p className="text-center text-red-600 pt-2 font-bold">
                            Jangan Melakukan Refresh Halaman
                          </p>
                          <p className="text-center text-red-600 pt-5 font-bold">
                            Sisa Waktu Pengerjaan
                          </p>
                          <p className="text-center text-red-600 font-bold">
                            {Math.floor(timeRemaining / 60)}:
                            {(timeRemaining % 60).toString().padStart(2, "0")}
                          </p>
                        </div>
                      </div>
                      <div className="px-4">
                        <p className="text-center font-bold text-xl lg:text-xl">
                          {question.questionText}
                        </p>
                        <div className="pt-5">
                          <ul className="w-full text-sm font-medium text-gray-900 bg-white border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            {question.choices.map((choice, choiceIndex) => (
                              <li
                                key={choiceIndex}
                                className="w-full border-gray-200 rounded-t-lg dark:border-gray-600"
                              >
                                <div className="flex items-center pl-3">
                                  <input
                                    id={`list-radio-${index}-${choiceIndex}`}
                                    type="radio"
                                    value=""
                                    name={`list-radio-${index}`}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    onChange={() =>
                                      handleRadioChange(index, choiceIndex)
                                    }
                                  />
                                  <label
                                    htmlFor={`list-radio-${index}-${choiceIndex}`}
                                    className="w-full py-3 ml-2 text-gray-900 text-xl lg:text-lg"
                                  >
                                    {choice.choiceText}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="flex flex-row justify-between py-4">
                          {index > 0 && (
                            <Button
                              onClick={() =>
                                props.tabsRef.current?.setActiveTab(index - 1)
                              }
                            >
                              Prev
                            </Button>
                          )}
                          {index < shuffledQuestions.length - 1 &&
                            hasAnswered[index] && (
                              <div>
                                <Button
                                  onClick={() =>
                                    props.tabsRef.current?.setActiveTab(index + 1)
                                  }
                                >
                                  Next
                                </Button>
                              </div>
                            )}
                          {/* disini buat form tapi hidden buat input */}
                          <form onSubmit={saveResult}>
                            <div className="hidden">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={nameUser}
                              />
                              <input
                                type="text"
                                name="nik"
                                id="nik"
                                value={nikUser}
                              />
                              <input
                                type="text"
                                name="soal_type"
                                id="soal_type"
                                value={soal_type}
                              />
                              <input
                                type="number"
                                name="correctChoices"
                                id="correctChoices"
                                value={numCorrectChoices}
                              />
                              <input
                                type="number"
                                name="incorrectChoices"
                                id="incorrectChoices"
                                value={numIncorrectChoices}
                              />
                            </div>
                            {/* table detail answer user */}
                            {/* berubah disini */}
                            {shuffledQuestions.map((question, index) => (
                              <div className="hidden" key={index}>
                                <input
                                  type="text"
                                  name="nik"
                                  id="nik"
                                  value={nikUser}
                                />
                                <input
                                  type="number"
                                  name="code_question"
                                  id="code_question"
                                  value={question.code_question}
                                />
                                <input
                                  type="text"
                                  name="code_question_answer"
                                  id="code_question_answer"
                                  value={
                                    question.userAnswerCorrect ? "true" : "false"
                                  }
                                />
                              </div>
                            ))}
                            <p>{msg}</p>

                            {index === shuffledQuestions.length - 1 &&
                              hasAnswered.every((answered) => answered) && (
                                <button
                                  type="submit"
                                  className={`bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-orange-500 rounded ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                                    }`}
                                  onClick={saveResult}
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Loading..." : "Selesaikan Soal"}
                                </button>
                              )}


                            {/* {index === shuffledQuestions.length - 1 &&
                            hasAnswered.every((answered) => answered) && (
                              <button
                                type="submit"
                                className="bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-orange-500 rounded"
                              >
                                Selesaikan Soal
                              </button>
                            )} */}
                          </form>
                        </div>
                        <p className="text-xs text-slate-300">
                          {question.created_by} - {datas.uuid}
                        </p>
                      </div>
                    </div>
                  </div>
                </Tabs.Item>
              ))}
            </Tabs.Group>
          </div>
        </div>
      ) : (<p>Loading...</p>)}
    </LayoutQuestion>
  );
}

export default Question;
