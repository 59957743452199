import axios from "axios";


const baseURLResult = 'https://apiprimacademy.pkss.co.id/results';
const baseURLUsers = 'https://apiprimacademy.pkss.co.id/users';
const baseURLAnswer = 'https://apiprimacademy.pkss.co.id/answers';
const baseURLprofile = 'https://apiprimacademy.pkss.co.id/me';

// const baseURLResult = 'http://182.253.211.156:81/results';
// const baseURLResultLen ='http://182.253.211.156:81/resultslen';
// const baseURLUsers = 'http://182.253.211.156:81/users';
// const baseURLAnswer = 'http://182.253.211.156:81/answers';
// const baseURLUsers = 'http://localhost:81/users';
// const baseURLResult = 'http://localhost:81/results';
// const baseURLAnswer = 'http://localhost:81/answers';

// export const profileData = async () => {
//   const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
//   try {
//     const response = await axios.post(baseURLprofile, {uuid: dataUuid} );
//     return response.data.map(item => ({
//       uuid: item.uuid,
//       nik: item.nik,
//       name: item.name,
//       role: item.role,
//     }));
//   } catch (error) {
//     console.error("Error fetching data Profile:", error);
//     throw error;
//   }
// }

export const fetchData = async () => {
  try {
    const response = await axios.get(baseURLResult);
    
    return response.data.map(item => ({
      nik: item.nik,
      name: item.name,
      benar: item.benar,
      salah: item.salah,
      test_id: item.test_id,
      soal_type: item.soal_type,
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const lengthData = async (value) => {
  try {
    // const response = await axios.get(`${baseURLResultLen}/${value}`);
    const response = await axios.get(baseURLResult);

    // Mengambil panjang data dari respons API
    const dataLength = response.data.length;

    return dataLength;
  } catch (error) {
    console.error("Error fetching data length:", error);
    throw error;
  }
};

export const fetchDataUser = async () => {
  try {
    const response = await axios.get(baseURLUsers);
    return response.data.map(item => ({
      id: item.id,
      uuid: item.uuid,
      nik: item.nik,
      name: item.name,
      role: item.role,
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export const fetchDataAnswer = async () => {
  try {
    const response = await axios.get(baseURLAnswer);
    
    return response.data.map(item => ({
      uuid: item.uuid,
      nik: item.nik,
      test_id: item.test_id,
      soal_type: item.soal_type,
      code_question: item.code_question,
      answer: item.answer
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

