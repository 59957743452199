import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Welcome = (props) => {
  const navigate = useNavigate();
  const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (dataUuid) {
        try {
          const response = await fetch('https://apiprimacademy.pkss.co.id/me', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': dataUuid,
            },
          });
          setDatas(await response.json());
        } catch (error) {
          console.error('Kesalahan saat mengambil data profil:', error);
        }
      } else {
        alert('Anda belum melakukan login');
        return navigate("/");
        // Opsional: Tangani pengalihan di sini menggunakan useNavigate
      }
    };

    fetchData();
  }, [dataUuid]);

  const gotoQuestion = () => {
    navigate("/question");
  }

  return (
    // <div><h1 className='text-xl font-semibold'>Dashboard</h1><h2>Welcome Back <strong>{user && user.name}</strong></h2></div>
    <div>
      <h1 className='text-xl font-semibold'>Dashboard Test Pelayanan Prima Pengemudi</h1>
      <h2>Welcome Back <b>{props.name}</b></h2>
      <h2><b>{props.nik}</b></h2>
      {datas && datas.role === "user" && (
        <button
          onClick={gotoQuestion}
          className="w-36 text-white bg-orange-600 hover:bg-bule-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
        >
          Mulai Test
        </button>

      )}
    </div>


  )
}

export default Welcome