import React, { useState } from 'react';
import { Button, Typography, Box, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function UploadFileArea({ onFileSelected }) {
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    onFileSelected(file);
  };

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={{
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: isDragging ? '#f5f5f5' : 'transparent',
        cursor: 'pointer',
      }}
    >
      <CloudUploadIcon sx={{ fontSize: 48, color: '#888' }} />
      <Typography variant="h6" gutterBottom>
        Drag &amp; drop file Excel di sini
      </Typography>
      <Typography variant="body2" color="textSecondary">
        atau klik untuk memilih file
      </Typography>
      {uploadProgress > 0 && (
        <LinearProgress variant="determinate" value={uploadProgress} />
      )}
    </Box>
  );
}

export default UploadFileArea;
