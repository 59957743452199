import React, {useEffect} from 'react'
import Layout from './layout'
import FormEditRole from '../component/FromEditRole'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../features/authSlice'

const EditRole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isError} = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if(isError){
      navigate("/")
    }
  }, [isError, navigate]);
  
  return (
    <Layout>
        <FormEditRole />
    </Layout>
  )
}

export default EditRole