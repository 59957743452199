import React from 'react'
import NavbarComponent from '../component/Navbar'
import SidebarComponent from '../component/Sidebar'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

const Layout = ({ children }) => {

    const navigate = useNavigate();
    const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
    const [datas, setDatas] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (dataUuid) {
                try {
                    const response = await fetch('https://apiprimacademy.pkss.co.id/me', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': dataUuid,
                        },
                    });
                    setDatas(await response.json());
                } catch (error) {
                    console.error('Kesalahan saat mengambil data profil:', error);
                }
            } else {
                alert('Anda belum melakukan login');
                return navigate("/");
                // Opsional: Tangani pengalihan di sini menggunakan useNavigate
            }
        };

        fetchData();
    }, [dataUuid]);
    return (
        <React.Fragment>
            {/* Navbar Component */}
            <div className="border-b-2 border-black">
                <NavbarComponent />
            </div>
            {/* Main Content */}
            <div className="flex min-h-screen">
                
            {datas && datas.role === "admin" && (
                <div className="">
                    <SidebarComponent />
                </div>
            )}
                <div className="pt-5">
                    <main>{children}</main>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Layout