'use client';
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import logo from "../pkss.png"
import { Navbar } from 'flowbite-react';


const NavbarComponent = () => {
    const navigate = useNavigate();

    const logout = () => {
        sessionStorage.removeItem('uuid');
        navigate("/");
    }

    return (
        <div>
            <Navbar fluid rounded>
                <Navbar.Brand>
                    <NavLink href="/dashboard" >
                        <img
                            alt="Flowbite React Logo"
                            className="mr-3 h-6 sm:h-9"
                            src={logo}
                        />
                    </NavLink>
                </Navbar.Brand>
                <div className="flex md:order-2">
                    <button onClick={logout}>
                        Log Out
                    </button>
                    {/* <Navbar.Toggle /> */}
                </div>
            </Navbar>
        </div>
    )
}

export default NavbarComponent