import { useState, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import { fetchDataAnswer } from "./makeData";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx"; 

const AnswersTable = () => {
    const [tableData, setTableData] = useState([]);
  
    useEffect(() => {
      fetchDataAnswer()
        .then((response) => {
          setTableData(response);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);
  
    const handleExportRows = (rows) => {
      console.log("Exporting selected rows", rows);
      const excelData = rows.map((row) => row.original);
      exportToExcel(excelData);
    };
  
    const handleExportData = () => {
      console.log("Exporting all data", tableData);
      exportToExcel(tableData);
    };
  
    const exportToExcel = (dataToExport) => {
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "answer_data.xlsx");
    };
  
    const columns =  [
        {
          accessorKey: "uuid",
          header: "Uuid",
        },
        {
          accessorKey: "nik",
          header: "Nik",
        },
        {
          accessorKey: "test_id",
          header: "Test Id",
          size: 50,
        },
        {
          accessorKey: "soal_type",
          header: "Tipe Soal",
          size: 50,
        },
        {
          accessorKey: "code_question",
          header: "Code Question",
          size: 50,
        },
        {
          accessorKey: "answer",
          header: "Answer",
          size: 50,
        },
      ];
  
    return (
      <>
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "left",
              },
              size: 10,
            },
          }}
          columns={columns}
          data={tableData}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              {/* along-side built-in buttons in whatever order you want them */}
              {/* <MRT_ToggleGlobalFilterButton table = {table} /> */}
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </Box>
          )}
          renderTopToolbarCustomActions={({table}) => (
            <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "0.5rem",
              flexWrap: "wrap",
            }}>
              <Button
                className='bg-blue-700'
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data to Excel
              </Button>
              <Button
                className='bg-blue-700'
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows to Excel
              </Button>
            </Box>
          )}
        />
      </>
    );
  };
  
  export default AnswersTable;