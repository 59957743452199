'use client';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getMe } from '../../features/authSlice'
import { useNavigate } from 'react-router-dom'
import LayoutQuestion from './layoutQuestion';

const ImagePick = () => {
    const videoRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        let stream;

        const enableCamera = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        };

        enableCamera();

        return () => {
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, []);

    const captureImage = () => {
        navigate('/question');
    };

    const dispatch = useDispatch();
    const { isError } = useSelector((state) => state.auth);

    // useEffect(() => {
    //     dispatch(getMe());
    // }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate("/")
        }
    }, [isError, navigate]);

    return (
        <LayoutQuestion>
            <div className="relative flex items-center justify-center">
                <div className="relative bg-gradient-to-r from-sky-400 to-orange-400 shadow-2xl p-4 rounded-xl">
                    <video ref={videoRef} autoPlay muted playsInline style={{ transform: 'scaleX(-1)' }} />
                    <div className='pt-4'>
                        <button onClick={captureImage} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>Ambil Gambar</button>
                    </div>
                </div>
            </div>
        </LayoutQuestion>
    );
};

export default ImagePick;

