'use client';
import React from 'react'
import { Table } from 'flowbite-react';
import { Link } from 'react-router-dom';

export default function RoleList() {
    return (
        <Table hoverable>
            <Table.Head>
                <Table.HeadCell>
                    No
                </Table.HeadCell>
                <Table.HeadCell>
                    Name
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        1
                    </Table.Cell>
                    <Table.Cell>
                        Admin
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        2
                    </Table.Cell>
                    <Table.Cell>
                        User
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}


