import React, { useEffect, useState } from 'react'
import { Grid, Container, Card, CardContent, CardHeader, Typography, Button } from '@mui/material';
import Countdown from '../../../component/Countdown';
import { useNavigate } from 'react-router-dom';



const CardTest = (props) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState(null);

    const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
    useEffect(() => {
        const fetchData = async () => {
            if (dataUuid) {
                try {
                    const response = await fetch('https://apiprimacademy.pkss.co.id/resultsId', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': dataUuid,
                        },
                    });
                    setDatas(await response.json());
                } catch (error) {
                    console.error('Kesalahan saat mengambil data profil:', error);
                }
            } else {
                alert('Anda belum melakukan login');
                return navigate("/");
                // Opsional: Tangani pengalihan di sini menggunakan useNavigate
            }
        };

        fetchData();
    }, [dataUuid]);

    return (
        <div>
            <Card>
                <CardHeader
                    title={props.title}
                    subheader={props.subtitle}
                />
                <CardContent>
                    <Typography variant="body2">
                        {props.date}
                    </Typography>
                    {/* <Button variant="contained" onClick={handleOpen}>Lihat Detail</Button>*/}
                    {/* { datas && datas.test_id !== null && ( */}
                        <Button variant="contained"><Countdown targetDate={props.targetDate} /></Button>
                    {/* ) } */}
                </CardContent>
            </Card>
        </div>
    )
}

export default CardTest