import React, { useState, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx"; // Import the xlsx library
import { fetchData } from "./makeData";

const columns = [
  {
    accessorKey: "nik",
    header: "Nik",
    size: 150,
  },
  {
    accessorKey: "name",
    header: "Name",
    size: 150,
  },
  {
    accessorKey: "benar",
    header: "Benar",
    size: 50,
  },
  {
    accessorKey: "salah",
    header: "Salah",
    size: 50,
  },
  {
    accessorKey: "score",
    header: "Score",
    size: 50,
    Cell: ({ row }) => (row.original.benar * 4),
  },
  {
    accessorKey: "test_id",
    header: "Test Id",
    size: 50,
  },
  {
    accessorKey: "soal_type",
    header: "Tipe Soal",
    size: 50,
  },
];


const ResultTable = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData()
      .then((response) => {
        // Menghitung nilai "Score" untuk setiap baris data dari API
        const updatedData = response.map((item) => ({
          ...item,
          score: (item.benar * 5),
        }));
        setData(updatedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleExportRows = (rows) => {
    console.log("Exporting selected rows", rows);
    const excelData = rows.map((row) => row.original);
    exportToExcel(excelData);
  };

  const handleExportData = () => {
    console.log("Exporting all data", data);
    exportToExcel(data);
  };

  const exportToExcel = (dataToExport) => {
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "exported_data.xlsx");
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableRowSelection
      positionToolbarAlertBanner="bottom"
      renderToolbarInternalActions={({ table }) => (
        <Box>
          {/* along-side built-in buttons in whatever order you want them */}
          {/* <MRT_ToggleGlobalFilterButton table = {table} /> */}
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_FullScreenToggleButton table={table} />
        </Box>
      )}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            p: "0.5rem",
            flexWrap: "wrap",
          }}
        >
          <Button
            color="primary"
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data to Excel
          </Button>
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Rows to Excel
          </Button>
          {/* <Button
            disabled={table.getRowModel().rows.length === 0}
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Page Rows to Excel
          </Button> */}
          {/* <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            onClick={() =>
              handleExportRows(table.getSelectedRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Selected Rows to Excel
          </Button> */}
        </Box>
      )}
    />
  );
};

export default ResultTable;
