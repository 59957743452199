import React, { useState } from 'react';
// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";
// import { LoginUser, reset } from "../features/authSlice";
import LayoutQuestion from '../pages/Question/layoutQuestion';
import * as XLSX from 'xlsx';

export default function Register() {
    const [name, setName] = useState("");
    const [nik, setNik] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [role, setRole] = useState("");
    const [jabatanId, setJabatanId] = useState("");
    const [cabangId, setCabangId] = useState("");
    const [file, setFile] = useState(null);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState("");
    const [progress, setProgress] = useState(0);

    const registerUser = async () => {
        try {
            const response = await fetch('https://apiprimacademy.pkss.co.id/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    nik: nik,
                    password: password,
                    confPassword: confPassword,
                    role: role,
                    cabangId: cabangId
                }),
            });

            if (response.ok) {
                // Login berhasil, lakukan sesuatu (misalnya, simpan token akses dan arahkan ke halaman profil)
                alert("Register Success. Check your profile")
            } else {
                // Login gagal, tampilkan pesan kesalahan
                alert('Register failed. Please check your credentials.');
            }
        } catch (error) {
            // Tangani kesalahan jika permintaan gagal
            alert(`An error occurred during Register. Please try again later.${error}`);
        }
    };

    const Auth = (event) => {
        event.preventDefault();
        registerUser();
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const registerUsersFromExcel = async () => {
        if (!file) {
            setError("Please select a file first.");
            return;
        }

        const reader = new FileReader();
        reader.onload = async (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            setUsers(jsonData);

            console.log(jsonData)

            for (let i = 0; i < jsonData.length; i++) {
                const user = jsonData[i];
                try {
                    const response = await fetch('https://apiprimacademy.pkss.co.id/users', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name: user.name,
                            nik: user.nik.toString(),
                            password: user.password.toString(),
                            confPassword: user.confPassword.toString(),
                            role: user.role
                        }),
                    });

                    if (response.ok) {
                        setProgress(((i + 1) / jsonData.length) * 100);
                    } else {
                        setError(`Failed to register user ${user.nik}`);
                    }
                } catch (error) {
                    setError(`An error occurred during register. ${error}`);
                }
            }

            if (progress === 100) {
                alert("All users registered successfully!");
            }
        };
        reader.readAsBinaryString(file);
    };

    return (
        <LayoutQuestion>
            <div className="relative">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <img className="relative bg-center h-auto w-60" src="logopkss.png" alt="logo" />
                        <form className="space-y-4 md:space-y-6" onSubmit={Auth}>
                            <div>
                                <label htmlFor="nik" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Register Nomer Induk Kerja</label>
                                <input
                                    type="text"
                                    name="nik"
                                    id="nik"
                                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="NIK PKSS"
                                    required
                                    value={nik}
                                    onChange={(e) => setNik(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="nik" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nama</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Name"
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder=" "
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="nik" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Konfirmasi Password</label>
                                <input
                                    type="password"
                                    name="confPassword"
                                    id="confPassword"
                                    placeholder=""
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required
                                    value={confPassword}
                                    onChange={(e) => setConfPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="nik" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                                <input
                                    type="text"
                                    name="role"
                                    id="role"
                                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Role"
                                    required
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="nik" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Jabatan Id</label>
                                <input
                                    type="text"
                                    name="jabatanId"
                                    id="jabatanId"
                                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Jabatan Id"
                                    required
                                    value={jabatanId}
                                    onChange={(e) => setJabatanId(e.target.value)}
                                />
                            </div>
                            
                            <div>
                                <label htmlFor="nik" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cabang Id</label>
                                <input
                                    type="text"
                                    name="cabangId"
                                    id="cabangId"
                                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Cabang Id"
                                    required
                                    value={cabangId}
                                    onChange={(e) => setCabangId(e.target.value)}
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full text-white bg-blue-600 hover:bg-bule-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Submit
                            </button>
                        </form>

                        <input type='file' onChange={handleFileChange} />

                        {error && <div style={{ color: 'red' }}>{error}</div>}
                        {progress > 0 && (
                            <div>Upload Progress: {progress}%</div>
                        )}

                        <button
                            onClick={registerUsersFromExcel}
                            className="w-full text-white bg-orange-600 hover:bg-bule-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                        >
                            Upload User
                        </button>

                        <p className='text-xs text-center'>{new Date().getFullYear()}  PT Prima Karya Sarana Sejahtera</p>
                    </div>
                </div>
            </div>
        </LayoutQuestion>
    );
}


