import React, { useEffect } from 'react'
import Layout from './layout'
import UserTable from '../component/Dashboard/userTable'

const Users = () => {

  return (
    <Layout>
      <UserTable />
    </Layout>
  )
}

export default Users