import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardTest from '../Modal/CardTest';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabDashboardUser() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const navigate = useNavigate();
  const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (dataUuid) {
        try {
          const response = await fetch('https://apiprimacademy.pkss.co.id/resultsUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': dataUuid,
            },
          });
          setDatas(await response.json());
        } catch (error) {
          console.error('Kesalahan saat mengambil data profil:', error);
        }
      } else {
        alert('Anda belum melakukan login');
        return navigate("/");
        // Opsional: Tangani pengalihan di sini menggunakan useNavigate
      }
    };

    fetchData();
  }, [dataUuid]);

  function waktuSelesai(params) {
    const date = new Date(params); // Inisialisasi Date dengan benar

    const formattedDate = date.toLocaleDateString('id-ID', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Asia/Jakarta' // Menentukan zona waktu Indonesia
    });

    const formattedTime = date.toLocaleTimeString('id-ID', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'Asia/Jakarta' // Menentukan zona waktu Indonesia
    });

    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Test" {...a11yProps(0)} />
          <Tab label="Materi" {...a11yProps(1)} />
          <Tab label="Achievements" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className='mb-5'>
          <CardTest title={'Tes Pengetahuan Satpam, Digital Mindset dan Guest Manager'} subtitle={'-'} date={'25-10-2024'} foto={''} targetDate={'2024-10-24T17:00:00+07:00'} />
        </div>
        <div>
          {datas && datas.length > 0 ? (
            datas.map((data, index) => (
              <div key={index}>
                <h2>Test ID <b>{data.test_id}</b></h2>
                <p>Benar: {data.benar} | Salah: {data.salah}</p>
                <p>Waktu Selesai: {waktuSelesai(data.createdAt)}</p>
              </div>
            ))
          ) : (
            <p>Belum ada data hasil test</p>
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Segera Hadir
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Segera Hadir
      </CustomTabPanel>
    </Box>
  );
}
