'use client';
import { Sidebar } from 'flowbite-react';
import { HiChartPie, HiDatabase, HiUser, HiCog } from 'react-icons/hi';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

export default function SidebarComponent() {

  const register = () => {
    navigate("/register");
  }

  const logout = () => {
    sessionStorage.removeItem('uuid');
    navigate("/");
  }


  //!-----------------------------
  const navigate = useNavigate();
  const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (dataUuid) {
        try {
          const response = await fetch('https://apiprimacademy.pkss.co.id/me', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': dataUuid,
            },
          });
          setDatas(await response.json());
        } catch (error) {
          console.error('Kesalahan saat mengambil data profil:', error);
        }
      } else {
        alert('Anda belum melakukan login');
        return navigate("/");
        // Opsional: Tangani pengalihan di sini menggunakan useNavigate
      }
    };

    fetchData();
  }, [dataUuid]);

  //!----------------------------- 

  return (
    <Sidebar aria-label="Sidebar with multi-level dropdown example">
      <Sidebar.Items>
        <Sidebar.ItemGroup>

          {datas && datas.role === "admin" && (
            <div>
              <Sidebar.Item
                href="/dashboard"
                icon={HiChartPie}
              >
                <p>
                  Dashboard
                </p>
              </Sidebar.Item>
              <Sidebar.Item
                href="/imagepick"
                icon={HiDatabase}
              >
                <p>
                  Preview Question
                </p>
              </Sidebar.Item>
              <div>
                <Sidebar.Collapse
                  icon={HiUser}
                  label="Admin"
                >
                <Sidebar.Item >
                  <button onClick={register}>
                    Register
                  </button>
                </Sidebar.Item>
                  <Sidebar.Item href="/users">
                    Users
                  </Sidebar.Item>
                  <Sidebar.Item href="/roles">
                    Role
                  </Sidebar.Item>
                </Sidebar.Collapse>
              </div>
              <Sidebar.Collapse
                icon={HiCog}
                label="Settings"
              >
                <Sidebar.Item >
                  <button onClick={logout}>
                    Log out
                  </button>
                </Sidebar.Item>
              </Sidebar.Collapse>
            </div>
          )}

          {datas && datas.role === "user" && (
            <div>
              <Sidebar.Item
                href="/question"
                icon={HiDatabase}
              >
                <p>
                  Mulai Test
                </p>
              </Sidebar.Item>
            </div>
          )}

            {datas ? (
              <lu>
                <li></li>
              </lu>
            ) : (
              <p>Loading...</p>
            )}

        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  )
}



