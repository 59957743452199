import React from 'react'
import { Grid, Container } from '@mui/material';
import TabDashboardUser from '../../component/Dashboard/DashboardUser/TabDashboardUser';

const cabangMap = {
    11: 'PKSS Surabaya',
    12: 'PKSS Semarang',
    13: 'PKSS Yogyakarta',
    14: 'PKSS Bandung',
    15: 'PKSS Jakarta-1',
    16: 'PKSS Palembang',
    17: 'PKSS Medan',
    18: 'PKSS Makassar',
    19: 'PKSS Denpasar',
    20: 'PKSS Banjarmasin',
    21: 'PKSS Padang',
    22: 'PKSS Manado',
    23: 'PKSS Aceh',
    24: 'PKSS Jakarta-2',
    25: 'PKSS Jakarta-3 Serang',
    26: 'PKSS Pontianak',
    27: 'PKSS Malang',
    29: 'PKSS Papua',
    30: 'PKSS Jakarta-4',
    31: 'PKSS Pekanbaru',
    32: 'PKSS Balikpapan',
    33: 'PKSS Bandar Lampung',
    34: 'PKSS Palangkaraya',
    35: 'PKSS Sorong',
    36: 'PKSS Kupang',
    37: 'PKSS Ternate',
    38: 'PKSS Palu',
    39: 'PKSS Bengkulu',
    40: 'PKSS Batam',
    41: 'PKSS Jambi',
    42: 'PKSS Kendari',
    43: 'PKSS Mataram',
    44: 'PKSS Cirebon',
    45: 'PKSS Purwokerto',
    69: 'PT BAKTI AMANAH SEJAHTERA',
    70: 'PKSS Pusat'
}

const clientMap = {
    2: 'DPRD Provinsi Jawa Timur'
}

const jabatanMap = {
    1: 'Administrasi Perkantoran',
    2: 'Operator Layanan Operasional',
    3: 'Penata Layanan Operasional',
    4: 'Pengelola Layanan Operasional',
    5: 'Pengelola Umum Operasional'
}

const DashboardUser = (props) => {
    const name = props.name;
    const nik = props.nik;
    
    const jabatan = props.jabatan !== null ? jabatanMap[props.jabatan] : "Pekerja Dasar";

    const cabang = props.cabangId !== null ? cabangMap[props.cabangId] : "Tidak Diketahui";

    const client = props.client !== null ? clientMap[props.client] : "PKSS";

    return (
        <div>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={15} md={15}>
                        <div>
                            <h1>Selamat Datang</h1>
                            <h1 className='mb-5'><b>{name}</b></h1>
                            <h1>Username / Nomer Induk Kerja <b>{nik}</b></h1>
                            <h1>Jabatan <b> {jabatan} </b></h1>
                            <h1>Cabang PKSS <b> {cabang} </b></h1>
                            <h1>Client <b> {client} </b></h1>
                            <h1>Unit Kerja <b> - </b></h1>
                        </div>
                    </Grid>
                    <Grid item xs={15} md={25}>
                        <div>
                            <TabDashboardUser />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default DashboardUser