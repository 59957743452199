import { BrowserRouter,Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./component/Login";
import Register from "./component/Register";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddRole from "./pages/AddRole";
import EditRole from "./pages/EditRole";
import Question from "./pages/Question/Question";
import ImagePick from "./pages/Question/ImagePick";
import Thanks from "./pages/Question/Thanks";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/roles/add" element={<AddRole />} />
          <Route path="/roles/edit/:1" element={<EditRole />} />
          <Route path="/imagepick" element={<ImagePick />} />
          <Route path="/question" element={<Question />} />
          <Route path="/thank" element={<Thanks />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
