import React, { useState, useRef, useEffect } from "react";
import { LogOut, reset } from "../../features/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LayoutQuestion = ({ children }) => {
  return (
    <React.Fragment>
      <div className="relative flex h-screen flex-col justify-center overflow-hidden  py-6 sm:py-12">
        <img
          src="bg.png"
          alt=""
          className="absolute bg-cover min-w-full min-h-full"
        />
        <img
          className="absolute inset-x-5 top-3 w-20 h-auto"
          src="logopkss.png"
        ></img>
        <div className="absolute inset-0 bg-center">
        </div>
        <div className="flex items-center justify-center h-screen">
          
          <div className="px-2">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LayoutQuestion;
