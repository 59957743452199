import React, {useEffect} from 'react'
import Layout from './layout'
import RoleList from '../component/Rolelist'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../features/authSlice'


const Roles = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isError} = useSelector((state) => state.auth);
  
    useEffect(() => {
      dispatch(getMe());
    }, [dispatch]);
  
    useEffect(() => {
      if(isError){
        navigate("/")
      }
    }, [isError, navigate]);
    
    return (
        <Layout>
            <RoleList />
        </Layout>
    )
}

export default Roles