import React, { useEffect, useState } from 'react'
import LayoutQuestion from './layoutQuestion'
import { useNavigate } from "react-router-dom";


const Thanks = () => {

    //!-----------------------------
    const navigate = useNavigate();
    const dataUuid = typeof window !== 'undefined' ? window.sessionStorage.getItem('uuid') : null;
    const [datas, setDatas] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (dataUuid) {
                try {
                    const response = await fetch('https://apiprimacademy.pkss.co.id/resultsId', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': dataUuid,
                        },
                    });
                    setDatas(await response.json());
                } catch (error) {
                    console.error('Kesalahan saat mengambil data profil:', error);
                }
            } else {
                alert('Anda belum melakukan login');
                return navigate("/");
                // Opsional: Tangani pengalihan di sini menggunakan useNavigate
            }
        };

        fetchData();
    }, [dataUuid]);

    //!----------------------------- 

    const gotoBackHome = () => {
        navigate("/dashboard");
    }

    return (
        <LayoutQuestion>
            <div className='relative bg-scroll text-center'>
                {datas ? (
                    <div>
                        <p className='font-bold text-2xl mb-5'>{datas.name}</p>
                        <h1>Benar : <b>{datas.benar}</b> | Salah : <b>{datas.salah}</b></h1>
                        <p className='font-semibold text-2xl my-5'>TERIMAKASIH TELAH MENYELESAIKAN</p>
                        <p className='font-semibold text-2xl my-5'></p>
                        <button
                            onClick={gotoBackHome}
                            className="w-60 text-white bg-orange-600 hover:bg-bule-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                        >
                            Kembali ke Beranda
                        </button>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </LayoutQuestion>
    )
}

export default Thanks