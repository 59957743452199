import React, { useState } from 'react';
// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
// import { LoginUser, reset } from "../features/authSlice";
import LayoutQuestion from '../pages/Question/layoutQuestion';

export default function Login() {
  const [nik, setNik] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginUser = async () => {
    try {
      const response = await fetch('https://apiprimacademy.pkss.co.id/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nik: nik,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // alert('Sorry, Web under Maintenance');
        // Login berhasil, lakukan sesuatu (misalnya, simpan token akses dan arahkan ke halaman profil)
        sessionStorage.setItem('uuid', data.uuid);
        return navigate("/dashboard");
      } else {
        // Login gagal, tampilkan pesan kesalahan
        alert('Login failed. Please check your credentials.');
      }
    } catch (error) {
      // Tangani kesalahan jika permintaan gagal
      alert(`An error occurred during login. Please try again later.${error}`);
    }
  };

  const Auth = (event) => {
    event.preventDefault();
    loginUser();
  }
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { user, isError, isSuccess, isLoading, message } = useSelector((state) => state.auth);


  // const Auth = (e) => {
  //     e.preventDefault();
  //     dispatch(LoginUser({ nik, password }));
  // };

  // LOOPING
  // useEffect(() => {
  //     if(user && user.role === "admin") return navigate("/dashboard");
  //     if(user && user.role === "user") return navigate("/question");
  //     // if(user || isSuccess) return navigate("/question")
  //     dispatch(reset());
  // }, [user, isSuccess, dispatch, navigate]);



  return (
    <LayoutQuestion>
      <div className="relative">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <img className="relative bg-center h-auto w-60" src="logopkss.png" alt="logo" />
            <form className="space-y-4 md:space-y-6" onSubmit={Auth}>
              <div>
                <label htmlFor="nik" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nomer Induk Kerja PKSS</label>
                <input
                  type="text"
                  name="nik"
                  id="nik"
                  className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="NIK PKSS"
                  required
                  value={nik}
                  onChange={(e) => setNik(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder=" "
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-bule-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </form>

            <p className='text-xs text-center'>{new Date().getFullYear()}  PT Prima Karya Sarana Sejahtera</p>
          </div>
        </div>
      </div>
    </LayoutQuestion>
  );
}


