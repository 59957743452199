import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton
} from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete, Edit, Add, Upload, Download } from '@mui/icons-material';

import * as XLSX from 'xlsx'; // Import the xlsx library

import { fetchDataUser } from "./makeData";
import axios from "axios";

const Example = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const inputRef = useRef();
  const [msg, setMsg] = useState("");

  useEffect(() => {
    fetchDataUser()
      .then((response) => {
        setTableData(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // const handleCreateNewRow = (values) => {
  //   tableData.push(values);
  //   setTableData([...tableData]);
  // };

  const handleCreateNewRow = async (values) => {
    try {
    await axios.post("https://apiprimacademy.pkss.co.id/users", {
      // await axios.post("http://182.253.211.156:81/users", {
        // await axios.post("http://localhost:81/users", {
        name: values.name,
        nik: values.nik,
        password: values.password,
        confPassword: values.confPassword,
        role: values.role,
      });
      window.location.reload();
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.getValue("uuid")] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      try {
        // Send a DELETE request to your API endpoint
        await axios.put(`https://apiprimacademy.pkss.co.id/users/${values}`);
        // await axios.put(`http://182.253.211.156:81/users/${values}`);
        window.location.reload();
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        } else {
          console.error("Error update data:", error);
        }
      }
      setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      const uuidUser = row.getValue("uuid");
      if (!window.confirm(`Are you sure you want to delete`)) {
        return;
      }
      try {
        // Debugging: Log the values.id to ensure it's correct
        console.log("Deleting user with ID:", uuidUser);

        // Send a DELETE request to your API endpoint
        await axios.delete(`https://apiprimacademy.pkss.co.id/users/${uuidUser}`);
        // await axios.delete(`http://182.253.211.156:81/users/${uuidUser}`);

        // Debugging: Log a message to indicate successful deletion
        console.log("User deleted successfully");

        // Refresh the page to reflect the updated data after deletion
        window.location.reload();
      } catch (error) {
        if (error.response) {
          // Handle error responses or set an error message
          setMsg(error.response.data.msg);
        } else {
          // Handle other types of errors here
          console.error("Error deleting data:", error);
        }
      }
    },
    [tableData]
  );

  const handleExcelUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Specify the header row with the expected column names
        const options = {
          header: 0, // Use the first row as the header
          raw: false // Convert values to strings
        };

        // Assuming the first sheet in the Excel file contains the data
        const sheet = workbook.Sheets[workbook.SheetNames[0]];

        // Convert the sheet data to an array of objects
        const jsonData = XLSX.utils.sheet_to_json(sheet, options);

        // Save the data to the database
        for (const item of jsonData) {
          if (item.name && item.nik && item.role && item.tgl_lahir) {
            // Replace the following with your actual database API call
            
            await axios.post('https://apiprimacademy.pkss.co.id/users', {
            // await axios.post('http://182.253.211.156:81/users', {
              name: item.name,
              nik: item.nik,
              password: item.tgl_lahir,
              confPassword: item.tgl_lahir,
              role: item.role
            });
          } else {
            console.error('Incomplete or incorrect data in the Excel file:', item);
          }
        }

        // Refresh the table data after importing
        fetchDataUser()
          .then((response) => {
            setTableData(response);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      } catch (error) {
        console.error('Error processing Excel file:', error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleExportData = () => {
    const header = [
      { name: "Budi", nik: "12345ABCDE", tgl_lahir: "18-10-2000", role: "Admin" },
      { name: "Budiman", nik: "ABCDE12345", tgl_lahir: "18-10-2000", role: "User" },
    ];
    exportToExcel(header);
  };

  const exportToExcel = (dataToExport) => {
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Template_User.xlsx');
  };

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "uuid",
        header: "Uuid",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 50,
      },
      {
        accessorKey: "nik",
        header: "Nik",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "password",
        header: "Password",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "password"
        }),
      },
      {
        accessorKey: "confPassword",
        header: "Confirmation Password",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "password"
        }),
      },
      {
        accessorKey: "name",
        header: "Name",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  const columnsModals = useMemo(
    () => [
      {
        accessorKey: "nik",
        header: "Nik",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "password",
        header: "Password",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "confPassword",
        header: "Confirmation Password",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "name",
        header: "Name",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "left",
            },
            size: 10,
          },
        }}
        columns={columns}
        data={tableData}
        initialState={{
          columnVisibility: {
            password: false,
            confPassword: false,
            uuid: false,
          },
        }}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Box>
            {/* along-side built-in buttons in whatever order you want them */}
            {/* <MRT_ToggleGlobalFilterButton table = {table} /> */}
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_FullScreenToggleButton table={table} />
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '0.5rem',
              flexWrap: 'wrap'
            }}
          >
            <Button
              color="secondary"
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
            >
              Create New Account
            </Button>
            <Button className='bg-blue-700' component="label" variant="contained" onClick={() => inputRef.current.click()} startIcon={<Upload />}>
              Upload Data
            </Button>
            <input type="file" accept=".xlsx, .xls" ref={inputRef} style={{ display: 'none' }} onChange={handleExcelUpload} />
            <Button className='bg-blue-700' component="label" variant="contained" onClick={handleExportData} startIcon={<Download />}>
              Download Template
            </Button>
          </Box>
        )}
      />
      <CreateNewAccountModal
        columns={columnsModals}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Account</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) => (
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;

export default Example;
